import React, { useMemo } from "react";
import { UpdateNoteDto } from "../../../../../../api";
import { Card, Text } from "../../../../../uiKit";
import { LocalesKeys } from "../../../../../../constants/localesKeys";
import { useNavigate } from "react-router-dom";
import { useDateHelpers, useRootStore } from "../../../../../../hooks";
import { useTranslation } from "react-i18next";

export interface IUpdateNoteCard {
  updateNote: UpdateNoteDto;
}

export const UpdateNoteCard = (props: IUpdateNoteCard) => {
  const { appStore } = useRootStore();
  const { t } = useTranslation();
  const { formatDate } = useDateHelpers();
  const navigate = useNavigate();
  const currentLocale = appStore.getAppLocale;

  // const updateNoteName = useMemo(
  //   () => props.updateNote?.contents?.find((item) => item.cultureKey === (currentLocale ?? LocalesKeys.en))?.name,
  //   [currentLocale]
  // );

  const updateNoteName = useMemo(() => {
    const locale = currentLocale ?? LocalesKeys.en;
    const localeUzEs = currentLocale?.includes("uz") ? LocalesKeys.ru : LocalesKeys.en;
    const localeNode = currentLocale?.includes("uz") || currentLocale?.includes("es")
      ? localeUzEs
      : locale;
    return props.updateNote?.contents?.find((item) => item.cultureKey === localeNode)?.name
  }, [currentLocale]);


  const handleRedirectToNote = () => {
    navigate(`/update-note/${props.updateNote.id}`);
  };

  return (
    <Card size="default" isShadowed={false} className="br-none" clickable onClick={handleRedirectToNote}>
      <div className="d-flex flex-nowrap align-center">
        <Text className="flex-grow-1" weight={500} children={updateNoteName} />
        {/*<div className="mr-auto">*/}
        {/*  <RegulationIndicator name={"123"} colorSchemeKey={"warning"} />*/}
        {/*</div>*/}
        <div className="d-stack-column align-end ml-2">
          <Text
            style={{ opacity: 0.5, whiteSpace: "nowrap" }}
            children={props.updateNote.isPublished ? t("parse:published") : t("parse:not_published")}
          />
          <Text
            style={{ opacity: 0.5, whiteSpace: "nowrap" }}
            children={
              t("parse:created_date") +
              ": " +
              formatDate(props.updateNote.dateCreated!, {
                formatObject: {
                  hour: undefined,
                  minute: undefined,
                },
              })
            }
          />
        </div>
      </div>
    </Card>
  );
};
