import React from "react";
import { Button, Dialog, DialogActions, Icon, Text } from "../../../uiKit";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { FiAlertCircle } from "react-icons/fi";
import "./EndAccessTimeDialog.scss";
import { IEndAccessTimeDialog } from "./EndAccessTimeDialog.interface";
import { openURL } from "../../../../helpers/urlFunctions";
import { useNotifier } from "../../../../hooks";
import { LucideHeadphones } from "lucide-react";
import clsx from "clsx";


export const EndAccessTimeDialog = (props: IEndAccessTimeDialog) => {
  const { t } = useTranslation();
  const notifier = useNotifier();

  const handleClickExtendAccess = () => {
    const link = `https://my.bbooster.online/platform?mail=${props.userOwnerEmail}`
    window.open(link, "_blank", "noreferrer");
  }


  const getTitle = () => {
    return props.isResident
      ? `${props.clientName},`
      : t("ui:end_access_time_dialog.title", {date: dayjs(props.endAccessTime).format("L")});
  }


  const getText = () => {
    if (props.isResident) {
      return props.isResidentEndAccessTime
        ? (
          <>
            <div>
              {t("ui:end_access_time_resident_dialog.text_access_time.first")}
            </div>
            <div>
              {t("ui:end_access_time_resident_dialog.text_access_time.second")}
            </div>
            <div style={{
              fontStyle: "italic"
            }}>
              {t("ui:end_access_time_resident_dialog.text.second")}
            </div>
            <div className={"mt-4"}>
              {t("ui:end_access_time_resident_dialog.text.third")}
            </div>
          </>
        )
        : (
          <>
            <div>
              {t("ui:end_access_time_resident_dialog.text.first",
               {
                 date: dayjs(props.endAccessTime).format("L"),
                 clientName: props.clientName
               })}
           </div>
            <div style={{
              fontStyle: "italic",
            }}>
             {t("ui:end_access_time_resident_dialog.text.second")}
           </div>
            <div className={"mt-4"}>
             {t("ui:end_access_time_resident_dialog.text.third")}
           </div>
          </>
        )
    }
    return t("ui:end_access_time_dialog.text");
  }

  const renderTitle = () => {
    return (
      <div className="end-access-time-dialog-title">
        {!props.isResident && (
          <div className="end-access-time-dialog-title__icon mb-2">
            <Icon component={() => <FiAlertCircle />}></Icon>
          </div>
        )}
        <Text
          style={{
            fontSize: 16,
            fontWeight: "bold"
          }}
        >
        { getTitle() }
        </Text>
      </div>
    )
  }

  const onCarrotQuestChatOpenClose = () => {
    if (window.carrotquest == null || window.carrotquest.open == null) {
      notifier.show({
        theme: "error",
        timeout: 10,
        message: t("parse:support_script_fail.message"),
        description: t("parse:support_script_fail.description"),
        button: {
          text: t("parse:support_script_fail.button"),
          onClick: () => openURL("https://t.me/TonnusSupportBot"),
        },
      });
      return;
    }
    window.carrotquest && window.carrotquest.open && window.carrotquest.open();
  };

  return (
    <Dialog
      width={props.isResident ? 919 : 652}
      zIndex={10000}
      title={renderTitle()}
      open={true}
      onClose={props.onClose}
      isNewDesign
      className={props.isResident ? "end-access-time-dialog" : ""}
    >
      <Text className="mb-4 end-access-time-dialog-text">
        { getText() }
      </Text>
      {props.isResident && (
        <div className={"mt-4"}>
          <Text className={"end-access-time-dialog-footer__text"}>
            { t("ui:end_access_time_resident_dialog.support_chat_text")}
          </Text>
        </div>
      )}
      <DialogActions>
        <div className={"end-access-time-dialog-footer"}>
          <div className={clsx("end-access-time-dialog-footer__buttons", {
            "end-access-time-dialog-footer__buttons_resident": props.isResident
          })}>
            <Button
              onClick={handleClickExtendAccess}
              size={"large"}
            >
              {t("ui:end_access_time_dialog.button")}
            </Button>
            {props.isResident && (
                <Button
                  onClick={onCarrotQuestChatOpenClose}
                  size={"large"}
                  type={"default"}
                >
                  <Icon>
                    <LucideHeadphones size={14} />
                  </Icon>

                  {t("common:misc.support_chat")}
                </Button>
            )}
          </div>
          {props.isResident && !props.isResidentEndAccessTime && (
            <Text
              className={"end-access-time-dialog-footer__ps"}
              type={"secondary"}
            >
              {t("ui:end_access_time_resident_dialog.ps", { date: dayjs(props.endAccessTime).format("L") })}
            </Text>
          )}
        </div>
      </DialogActions>

    </Dialog>
  )
}

