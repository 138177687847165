import React, { useEffect, useMemo, useRef, useState } from "react";
import { findId } from "../../../../../../helpers/pathname";
import { useLocation, useNavigate } from "react-router-dom";
import { api } from "../../../../../../services";
import { useTranslation } from "react-i18next";
import { useNotifier, useRootStore, useSequentialPromises } from "../../../../../../hooks";
import PageHeader from "../../../../layouts/pageHeader/PageHeader";
import { CustomConfirmDialog } from "../../../../dialogs/customConfirmDialog/СustomConfirmDialog";
import { UpdateNoteHeader } from "../../headers/updateNoteHeader/UpdateNoteHeader";
import PageContent from "../../../../layouts/pageContent/PageContent";
import { UpdateNoteContent } from "../../contents/UpdateNoteContent/UpdateNoteContent";
import { LocalesKeys } from "../../../../../../constants/localesKeys";
import { IInitialUpdateNotes, initialNotes, publishedSelectKeys } from "../../misc/constants";
import { UpdateNoteContentDto, UpdateNoteDto } from "../../../../../../api";
import { convertIsPublishedToSelectKeys, convertNotesDtoToCustomNotes } from "../../helpers";
import './UpdateNote.scss';

export const UpdateNote = () => {
  const { appStore, authStore } = useRootStore();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const notifier = useNotifier();
  const navigate = useNavigate();
  const promisesListRef = useRef(useSequentialPromises());
  const initialUpdateNoteRef = useRef<UpdateNoteDto>();
  const editorRef = useRef<{ resetEditor: () => void }>();

  const updateNoteId = findId(pathname);

  const [currentLocale, setCurrentLocale] = useState<LocalesKeys>(
    (appStore.getAppLocale as LocalesKeys) ?? LocalesKeys.en
  );

  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState<boolean>(false);

  const [isLoadingDeleteBtn, setIsLoadingDeleteBtn] = useState<boolean>(false);
  const [isLoadingSaveBtn, setIsLoadingSaveBtn] = useState<boolean>(false);

  const [draftUpdateNoteContents, setDraftUpdateNoteContents] = useState<IInitialUpdateNotes>();
  const [publishKey, setPublishKey] = useState<publishedSelectKeys>(publishedSelectKeys.notPublished);

  const [isHideUpdateModal, setIsHideUpdateModal] = useState<boolean>(false);

  useEffect(() => {
    if (!isEditable && initialUpdateNoteRef.current) {
      handleUpdateNote();

    }
  }, [isHideUpdateModal]);

  const isCurrentUserAdmin = useMemo(
    () => !!authStore.getInitialInfo?.identity?.admin,
    [authStore.getInitialInfo?.identity]
  );

  const locale = currentLocale ?? LocalesKeys.en;
  const localeUzEs = currentLocale?.includes("uz") ? LocalesKeys.ru : LocalesKeys.en;
  const localeNode = currentLocale?.includes("uz") || currentLocale?.includes("es")
    ? localeUzEs
    : locale;

  const currentDraftNote: UpdateNoteContentDto | undefined =
    draftUpdateNoteContents && draftUpdateNoteContents[isCurrentUserAdmin ? currentLocale : localeNode];

  const handleEnableEditable = () => setIsEditable(true);
  const handleCancelEditable = () => setIsEditable(false);

  const handleOpenConfirmDialog = () => setIsOpenConfirmDialog(true);
  const handleCloseConfirmDialog = () => {
    setIsOpenConfirmDialog(false);
    promisesListRef.current.reset();
  };

  const handleChangeIsPublished = (value: publishedSelectKeys) => {
    setPublishKey(value);
    handleUpdateNote(value);
  };

  const handleChangeNoteContent = (content: string) =>
    setDraftUpdateNoteContents((prev) => {
      const newContent: IInitialUpdateNotes = prev ?? JSON.parse(JSON.stringify(initialNotes));
      newContent[currentLocale].content = content;
      return Object.assign({}, prev);
    });

  const handleChangeNoteName = (name: string) =>
    setDraftUpdateNoteContents((prev) => {
      const newContent: IInitialUpdateNotes = prev ?? JSON.parse(JSON.stringify(initialNotes));
      newContent[currentLocale].name = name;
      return Object.assign({}, prev);
    });

  const handleChangeCurrentLocale = (newLocale: LocalesKeys) => setCurrentLocale(newLocale);

  const handleApiError = () => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });

  const handleLoadNote = async () => {
    setIsLoading(true);
    const r = await api.updateNote.getById(updateNoteId!);
    setIsLoading(false);
    if (r == null) return handleApiError();
    setIsHideUpdateModal(!r.showUpdateModal);
    setPublishKey(convertIsPublishedToSelectKeys(r.isPublished));
    const customNotes = convertNotesDtoToCustomNotes(r);
    setDraftUpdateNoteContents({ ...customNotes });
    initialUpdateNoteRef.current = r;
  };

  const handleDeleteNote = async () => {
    setIsLoadingDeleteBtn(true);
    const r = await api.updateNote.del(updateNoteId!);
    setIsLoadingDeleteBtn(false);
    if (r == null) return handleApiError();
    else notifier.show({ message: t("notifier:success.release_delete"), theme: "success" });
    navigate(`/update-note`);
  };

  const handleUpdateNote = async (withPublish?: publishedSelectKeys) => {
    setIsLoadingSaveBtn(true);
    const r = await api.updateNote.edit(updateNoteId!, {
      ...initialUpdateNoteRef.current,
      isPublished: (withPublish ?? publishKey) == publishedSelectKeys.published,
      showUpdateModal: !isHideUpdateModal,
      // isPublished: convertSelectKeysToIsPublished(withPublish ?? publishKey),
      contents: Object.values(draftUpdateNoteContents!),
    });
    setIsLoadingSaveBtn(false);
    if (r == null) return handleApiError();
    else {
      setPublishKey(convertIsPublishedToSelectKeys(r.isPublished));
      notifier.show({ message: t("notifier:success.release_update"), theme: "success" });
    }
    handleCancelEditable();
  };

  const handleClickDelete = () => {
    handleOpenConfirmDialog();
    promisesListRef.current.add(handleDeleteNote);
  };

  const handleSuccessConfirm = async () => {
    setIsLoadingDeleteBtn(true);
    await promisesListRef.current.run();
    setIsLoadingDeleteBtn(false);
    handleCloseConfirmDialog();
  };

  useEffect(() => {
    updateNoteId && handleLoadNote();
  }, [updateNoteId]);

  useEffect(() => {
    editorRef.current?.resetEditor();
  }, [currentLocale]);

  return (
    <>
      <CustomConfirmDialog
        open={isOpenConfirmDialog}
        onClose={handleCloseConfirmDialog}
        onConfirm={handleSuccessConfirm}
        loading={isLoadingDeleteBtn}
        title={t("ui:title.confirm_deletion")}
      />
      { isCurrentUserAdmin ? (
        <PageHeader>
          <UpdateNoteHeader
            publishOption={publishKey}
            currentLocale={currentLocale}
            disabledSaveBtn={false}
            isEditable={isEditable}
            onChangeLocale={handleChangeCurrentLocale}
            onChangePublishOption={handleChangeIsPublished}
            onCancelEditable={handleCancelEditable}
            onEnableEditable={handleEnableEditable}
            isLoadingDeleteBtn={isLoadingDeleteBtn}
            isLoadingSaveBtn={isLoadingSaveBtn}
            onDelete={handleClickDelete}
            onSave={handleUpdateNote}
            isHideUpdateModal={isHideUpdateModal}
            onChangeIsHideUpdateModal={setIsHideUpdateModal}
          />
        </PageHeader>
      ) : null}

      <PageContent isLoading={isLoading} contentPosition={{ maxWidth: "1000px" }}>
        {currentDraftNote && (
          <div className={`update-note-wrapper ${!isCurrentUserAdmin ? 'update-note-wrapper_user' : ''}`}>
            <UpdateNoteContent
              ref={editorRef}
              noteName={currentDraftNote!.name!}
              draftUpdateNoteContent={currentDraftNote!.content!}
              isEditable={isEditable}
              onChangeDraftUpdateNoteContent={handleChangeNoteContent}
              onChangeNoteName={handleChangeNoteName}
            />
          </div>
        )}
      </PageContent>
    </>
  );
};
