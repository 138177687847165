export enum LocalesKeys {
  uk = "uk",
  en = "en",
  ru = "ru",
  zh = "zh",
  // es = "es",
  // uz = "uz",
  // uzLatn = "uzLatan-Latn",
  // uzCyrl = "uzLatan-Cyrl",
}
